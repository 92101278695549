.heading {
  @apply w-full bg-white p-4;
}

.content {
  @apply p-4;
}

.box {
  @apply border border-gray-200 p-4 rounded-md bg-white;
}

.box-group .box {
  @apply rounded-none border-t-0 first:rounded-t-md first:border-t last:rounded-b-md shadow;
}

.button {
  @apply border border-blue-600 rounded-md p-2;
}

.button.active {
  @apply bg-blue-700 text-white;
}

.button-group .button {
  @apply inline-block rounded-none border-l-0 first:rounded-l-md first:border-l last:rounded-r-md;
}

.is-pulled-right {
  @apply float-right;
}

.title {
  @apply text-xl font-bold;
}

.meta {
  @apply text-gray-400;
}

p {
  @apply mb-4;
}

form {
  @apply bg-white py-4;
}

.field {
  @apply m-4;
}

.field label {
  @apply block;
}

.field input {
  @apply w-full bg-gray-200 h-12 p-2 rounded;
}

.actions {
  @apply flex justify-end;
}
