.breadcrumb {
  @apply ml-2 text-sm text-blue-200;
}

.breadcrumb ul {
  @apply list-none;
}

.breadcrumb li {
  @apply inline;
}

.breadcrumb li.is-active {
  @apply text-gray-400;
}

.breadcrumb li::after {
  content: " > ";
}

.breadcrumb li:last-child::after {
  content: "";
}
