.attendance-table {
  @apply w-full bg-white shadow rounded;
}

.bottom-filler {
  height: 3.75em;
}

.attendance-table th,
td {
  @apply p-2;
}

.attendance-table tr {
  @apply odd:bg-gray-50;
}

.attendance-table th {
  @apply text-left;
}

.attendance-table input {
  @apply w-6 h-6;
}
